import { default as _91form_93X4AseZAcWjMeta } from "/codebuild/output/src1270189446/src/form.winx.ai/pages/[[research]]/[form].vue?macro=true";
import { default as deniedvOa2VfdYpWMeta } from "/codebuild/output/src1270189446/src/form.winx.ai/pages/[[research]]/denied.vue?macro=true";
import { default as finishjQkpyoPSgeMeta } from "/codebuild/output/src1270189446/src/form.winx.ai/pages/[[research]]/finish.vue?macro=true";
import { default as interaction6v1WY0ahtEMeta } from "/codebuild/output/src1270189446/src/form.winx.ai/pages/[[research]]/interaction.vue?macro=true";
import { default as startqCMajRxRTfMeta } from "/codebuild/output/src1270189446/src/form.winx.ai/pages/[[research]]/start.vue?macro=true";
import { default as _91_91research_93_93qX8PtXxj2gMeta } from "/codebuild/output/src1270189446/src/form.winx.ai/pages/[[research]].vue?macro=true";
import { default as indexVhKb1uG7oNMeta } from "/codebuild/output/src1270189446/src/form.winx.ai/pages/index.vue?macro=true";
import { default as loginG4WNgU0LAxMeta } from "/codebuild/output/src1270189446/src/form.winx.ai/pages/login.vue?macro=true";
import { default as redirectqYQJfljRrqMeta } from "/codebuild/output/src1270189446/src/form.winx.ai/pages/redirect.vue?macro=true";
export default [
  {
    name: _91_91research_93_93qX8PtXxj2gMeta?.name ?? "research",
    path: _91_91research_93_93qX8PtXxj2gMeta?.path ?? "/:research?",
    meta: _91_91research_93_93qX8PtXxj2gMeta || {},
    alias: _91_91research_93_93qX8PtXxj2gMeta?.alias || [],
    redirect: _91_91research_93_93qX8PtXxj2gMeta?.redirect,
    component: () => import("/codebuild/output/src1270189446/src/form.winx.ai/pages/[[research]].vue").then(m => m.default || m),
    children: [
  {
    name: _91form_93X4AseZAcWjMeta?.name ?? "research-form",
    path: _91form_93X4AseZAcWjMeta?.path ?? ":form()",
    meta: _91form_93X4AseZAcWjMeta || {},
    alias: _91form_93X4AseZAcWjMeta?.alias || [],
    redirect: _91form_93X4AseZAcWjMeta?.redirect,
    component: () => import("/codebuild/output/src1270189446/src/form.winx.ai/pages/[[research]]/[form].vue").then(m => m.default || m)
  },
  {
    name: deniedvOa2VfdYpWMeta?.name ?? "research-denied",
    path: deniedvOa2VfdYpWMeta?.path ?? "denied",
    meta: deniedvOa2VfdYpWMeta || {},
    alias: deniedvOa2VfdYpWMeta?.alias || [],
    redirect: deniedvOa2VfdYpWMeta?.redirect,
    component: () => import("/codebuild/output/src1270189446/src/form.winx.ai/pages/[[research]]/denied.vue").then(m => m.default || m)
  },
  {
    name: finishjQkpyoPSgeMeta?.name ?? "research-finish",
    path: finishjQkpyoPSgeMeta?.path ?? "finish",
    meta: finishjQkpyoPSgeMeta || {},
    alias: finishjQkpyoPSgeMeta?.alias || [],
    redirect: finishjQkpyoPSgeMeta?.redirect,
    component: () => import("/codebuild/output/src1270189446/src/form.winx.ai/pages/[[research]]/finish.vue").then(m => m.default || m)
  },
  {
    name: interaction6v1WY0ahtEMeta?.name ?? "research-interaction",
    path: interaction6v1WY0ahtEMeta?.path ?? "interaction",
    meta: interaction6v1WY0ahtEMeta || {},
    alias: interaction6v1WY0ahtEMeta?.alias || [],
    redirect: interaction6v1WY0ahtEMeta?.redirect,
    component: () => import("/codebuild/output/src1270189446/src/form.winx.ai/pages/[[research]]/interaction.vue").then(m => m.default || m)
  },
  {
    name: startqCMajRxRTfMeta?.name ?? "research-start",
    path: startqCMajRxRTfMeta?.path ?? "start",
    meta: startqCMajRxRTfMeta || {},
    alias: startqCMajRxRTfMeta?.alias || [],
    redirect: startqCMajRxRTfMeta?.redirect,
    component: () => import("/codebuild/output/src1270189446/src/form.winx.ai/pages/[[research]]/start.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexVhKb1uG7oNMeta?.name ?? "index",
    path: indexVhKb1uG7oNMeta?.path ?? "/",
    meta: indexVhKb1uG7oNMeta || {},
    alias: indexVhKb1uG7oNMeta?.alias || [],
    redirect: indexVhKb1uG7oNMeta?.redirect,
    component: () => import("/codebuild/output/src1270189446/src/form.winx.ai/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginG4WNgU0LAxMeta?.name ?? "login",
    path: loginG4WNgU0LAxMeta?.path ?? "/login",
    meta: loginG4WNgU0LAxMeta || {},
    alias: loginG4WNgU0LAxMeta?.alias || [],
    redirect: loginG4WNgU0LAxMeta?.redirect,
    component: () => import("/codebuild/output/src1270189446/src/form.winx.ai/pages/login.vue").then(m => m.default || m)
  },
  {
    name: redirectqYQJfljRrqMeta?.name ?? "redirect",
    path: redirectqYQJfljRrqMeta?.path ?? "/redirect",
    meta: redirectqYQJfljRrqMeta || {},
    alias: redirectqYQJfljRrqMeta?.alias || [],
    redirect: redirectqYQJfljRrqMeta?.redirect,
    component: () => import("/codebuild/output/src1270189446/src/form.winx.ai/pages/redirect.vue").then(m => m.default || m)
  }
]